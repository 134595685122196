import React from 'react'
import styled from 'styled-components'

const AboutWrapper = styled.div`
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    margin-bottom: 45px;
    padding: 25px 40px;
    color: #34424a;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    align-items: center;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        padding: 25px;
    }
`

const ImageContainer = styled.div`
    grid-columns: span 1;
    text-align: center;

    img {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
`

const DescriptionContainer = styled.div`
    grid-column: span 2;
    font-size: 1em;
`

const About = () => (
    <AboutWrapper>
        <ImageContainer>
            <img src="/images/about-photo.png" alt="about raymond ware" scale="0" />
        </ImageContainer>
        <DescriptionContainer>
            <h2>About Me</h2>
            <p>Hi, I am Full Stack Developer. I am passionate about JavaScript, and find myself working on a lot of React based projects.</p>
        </DescriptionContainer>
    </AboutWrapper>
)

export default About
