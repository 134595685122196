import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ArticleWrapper = styled.div`
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    margin-bottom: 45px;
    padding: 25px 40px;
    color: #34424a;

    h1.main-title {
        font-size: 32px;
        line-height: 1.2em;
        margin: 25px 0 35px;
        padding-bottom: 15px;
        border-bottom: 4px solid #f1e663;
    }

    img {
        max-width: 100%;
    }

    ul, ol {
        li {
            margin-bottom: 25px;
        }
    }

    a {
        color: #932eef;
        text-decoration: underline;
    }

    @media screen and (max-width: 650px) {
        padding: 25px;
    }
`

const MetaInfo = styled.div`
    font-size: 13px;
    margin-bottom: 7px;

    ul {
        list-style: none;
        margin: 0 0 0 7px;
        padding: 0;
        display: inline-block;

        li {
            margin: 0 7px 0 0;
            padding: 0;
            list-style: none;
            color: #bdc9d1;
            display: inline-block;
        }
    }
`

const Banner = styled.div`
    max-height: 300px;
    margin-bottom: 45px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,1) 100%);
        opacity: .75;
        z-index: 2;
    }
    
    img {
        width: 100%;
    }
`

const Article = ({ image, title, date, tags, content }) => (
    <ArticleWrapper>
        <MetaInfo>
            {date} |
            <ul>
                {tags.map((tag) => (<li>{tag}</li>))}
            </ul>
        </MetaInfo>
        <h1 className="main-title">{title}</h1>
        {image ? <Banner><img srcSet={image} alt={title} /></Banner> : ''}
        <div dangerouslySetInnerHTML={({__html:content})} />
    </ArticleWrapper>
)

Article.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    content: PropTypes.object.isRequired
}

Article.defaultProps = {
    image: ''
}

export default Article
