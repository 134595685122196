import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Disqus } from 'gatsby-plugin-disqus'

import Layout from '../../layouts/Layout'
import NavBar from '../NavBar'
import Overlay from '../Overlay'
import Article from '../Article'
import About from './About'
import Footer from '../../sections/Footer'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  background: url('/images/wood-bg.jpg');
  box-shadow: 0 12px 30px 0 rgba(0,0,0,0.11),
  0 5px 15px 0 rgba(0,0,0,0.08);
`

const Hero = styled.div`
  background-image: url('/images/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  color: #fff;
  padding: 20px 15px 200px;

  h1 {
    text-align: center;
    font-size: 47px;
    text-transform: uppercase;
    margin: 40px 0 55px;
    line-height: 1em;
    position: relative;
    z-index: 2222;

    &:after {
      content: "";
      display: block;
      background-image: -webkit-linear-gradient(180deg,#61a9e2,#815cec 50%,#f1e763);
      height: 4px;
      width: 140px;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 20px 15px 145px;
  }
`

const SingleContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 55px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 25px;
  z-index: 2222;

  @media screen and (max-width: 650px) {
    padding: 0 15px;
  }
`

const ArticleWrapper = styled.div`
  position: relative;
  display: inline-block;
  top: -150px;
  margin-bottom: -120px;
  grid-column: span 3;

  @media screen and (max-width: 650px) {
    grid-column: span 2;
  }
`

const ContentWrapper = styled.div`
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
  margin-bottom: 45px;
  padding: 25px 40px;
  color: #34424a;

  @media screen and (max-width: 650px) {
    padding: 25px;
  }
`

const Sidebar = styled.aside`
  margin: 45px auto 0;
  text-align: center;

  @media screen and (max-width: 800px) {
    grid-column: span 2;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
`

const Ad = styled.a`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 25px;
  text-align: center;

  img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`

const BlogSingle = ({ data }) => {
  const post = data.contentfulBlog

  const [amazBool, setAmazBool] = useState(false)

  useEffect(() => {
    setAmazBool(true)
  })

  const disqusConfig = {
    url: `https://www.raymondware.com/blog/${post.slug}/`,
    identifier: post.id,
    title: post.title
  }

  return (
    <Wrapper>
      <Layout amazonStrip={amazBool} siteTitle={`${post.title} :: Raymond Ware`} siteDescription={post.shortDescription.shortDescription} siteImage={post.featuredImage.sizes.src} />
      <Hero>    
        <Overlay bg="linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,0) 100%);" opacityVal=".98" display="none" mediaDisplay="2460px" />
          <NavBar />
      </Hero>
      <SingleContainer>
        <ArticleWrapper>
          <Article title={post.title} date={post.createdAt} tags={post.tags} content={post.content.childMarkdownRemark.html} />

          <About />

          <ContentWrapper>
            <Disqus config={disqusConfig} />
          </ContentWrapper>
        </ArticleWrapper>

        <Sidebar>
          <Ad href="https://m.do.co/c/ba87d146cb74"><img src="/images/ads/do-adArtboard-2-1.jpg" alt="digital ocean deal" /></Ad>
          <Ad href="https://www.shopify.com/?ref=raymond-ware-design"><img src="/images/ads/shopify-300x250-1.jpg" alt="shopify deal" /></Ad>
        </Sidebar>
      </SingleContainer>
      <Footer />
    </Wrapper>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: {eq: $slug}) {
      title
      shortDescription {
        shortDescription
      }
      id
      slug
      tags
      featuredImage {
        sizes {
          src
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      createdAt(fromNow: true)
    }
  }
`

BlogSingle.propTypes = {
  data: PropTypes.object.isRequired
}

export default BlogSingle
